<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <!--begin: Wizard Form-->
            <div class="row">
              <div class="col-md-8 col-sm-12 col-lg-8 col-xs-12">
                <h4>
                  <router-link :to="{name:'books'}">
                    <i class="fas fa-arrow-left"></i>
                  </router-link>
                  Book Detail
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <div class="row">
                  <div class="col-6">
                    <table class="table mt-3">
                      <tr>
                        <th>Book</th>
                        <td class="font-weight-bold">{{ book.title }}</td>
                      </tr>
                      <tr>
                        <th>Author</th>
                        <td class="font-weight-bold">{{ book.author }}</td>
                      </tr>
                      <tr>
                        <th>ISBN</th>
                        <td class="font-weight-bold">{{ book.isbn_no }}</td>
                      </tr>
                      <tr>
                        <th>Classification No.</th>
                        <td class="font-weight-bold">{{ book.dewey_decimal_class_title }}</td>
                      </tr>
                      <tr>
                        <th>Publisher</th>
                        <td class="font-weight-bold">{{ book.publisher }}</td>
                      </tr>
                      <tr>
                        <th>Publication</th>
                        <td class="font-weight-bold">{{ book.publication }}</td>
                      </tr>
                      <tr>
                        <th>Edition</th>
                        <td class="font-weight-bold">{{ book.edition }}</td>
                      </tr>
                      <tr>
                        <th>Publish Year</th>
                        <td class="font-weight-bold">{{ book.year_of_publication }}</td>
                      </tr>
                      <tr>
                        <th>No of pages</th>
                        <td class="font-weight-bold">{{ book.no_of_pages }}</td>
                      </tr>
                      <tr>
                        <th>Stock</th>
                        <td>
                          <span> <i class="fas" :class="book.in_stock?'text-success fa-check':'text-danger fa-ban'"></i></span>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div class="col-12">
                    <p>{{ book.description }}</p>
                  </div>

                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="col">
                  <img :src="book.image_path?book.image_path['real']:null" :alt="book.title" width="100%" height="300px"
                       class="img-responsive">
                </div>
                <div class="col mt-3">
                  <img v-if="book && book.book_barcode" :src="getBarCode(book.book_barcode)" height="100px"
                       width="100%"/>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <pre>
</pre>
  </v-app>
</template>
<script>

import BookService from "@/core/services/library/book/BookService";


const bookService = new BookService();

export default {
  name: "book-detail",
  data() {
    return {
      isBusy: false,
      edit: false,
      menu: false,
      menu1: false,
      manage_inventory: false,
      current_year: null,
      classifications: [],
      book: {},
    }
  },  computed: {
    book_id() {
      return this.$route.params.id
    },
  }, mounted() {
    if (this.book_id) {
      this.getBook();
    }
  }, methods: {
    getBook() {
      bookService.show(this.book_id).then(response => {
        this.book = response.data.book
        this.edit = true
      })
    }, redirectTo() {
      this.$router.push({name: "book-list"})
    },
    getBarCode(barcode) {
      return "data:image/png;base64," + barcode
    }
  }
}
</script>

<style scoped>

</style>
